<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="component_type == 'edit'"
              >Edit User Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="component_type == 'add'">Add User</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <!-- confirmations -->
      <v-dialog v-model="confirming_contact_info" max-width="500">
        <v-card>
          <v-card-title class="headline orange--text">
            <v-icon class="mr-4 orange--text"> mdi-alert-outline </v-icon>
            {{ temp_contact_info ? temp_contact_info.type : "" }}
            Confirmation
          </v-card-title>

          <v-card-text>
            <div class="mt-5 text-body-1">
              <p>
                The {{ temp_contact_info ? temp_contact_info.type : "" }} you
                are trying to add is currently used by the following user:
              </p>

              <div
                v-for="user in temp_contact_info ? temp_contact_info.users : []"
                :key="user.id"
              >
                <p>• {{ user.first_name }} {{ user.last_name }}</p>
              </div>

              <p>
                Please confirm if you wish to use the same
                {{ temp_contact_info ? temp_contact_info.type : "" }}. This
                phone number will be associated with multiple users.
              </p>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green" text @click="handle_confirm_contact_info">
              Confirm
            </v-btn>

            <v-btn
              clss="ml-5"
              color="red"
              text
              @click="handle_close_confirm_contact_info"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="component_type == 'edit'">Edit User Information</span
            ><span v-if="component_type == 'add'">Add User</span>
          </h3>

          <p class="py-4">
            Please fill out the form below to add a user.
            <br /><br />
            <v-alert type="error"
              >After creating a user, you will need to associate their account
              to a company so they can log in and view opportunities. If you
              fail this step, they will have issues after log in.
              <br /><br />HAS THE COMPANY BEEN CREATED FIRST?</v-alert
            >
          </p>
          <v-form @submit="submitCreateUser" ref="form" v-model="form.valid">
            <v-text-field
              v-model="userData.first_name"
              label="First Name"
              :rules="form.rules.required"
              outlined
            >
            </v-text-field>
            <v-text-field
              v-model="userData.last_name"
              label="Last Name"
              :rules="form.rules.required"
              outlined
            >
            </v-text-field>
            <v-text-field
              v-model="userData.email"
              label="Primary Email"
              :rules="form.rules.emailRules"
              outlined
            >
            </v-text-field>
            <v-textarea
              v-model="userData.description"
              label="Description"
              outlined
            ></v-textarea>

            <!-- <v-text-field
              label="Phone Number"
              placeholder="Phone Number"
              outlined
              v-model="userData.phone"
              :rules="form.rules.phoneRules"
            ></v-text-field> -->

            <v-divider class="my-5" />
            <p class="grey--text text-subtitle-2">Contact Information :</p>

            <v-row>
              <v-col>
                <v-row
                  v-for="(contact_info, index) in userData.contact_infos"
                  :key="contact_info.order"
                  class="mb-5"
                >
                  <v-col cols="4">
                    <v-select
                      v-model="contact_info.type"
                      :items="contact_type_options"
                      label="Contact Type"
                      hide-details="auto"
                      @change="
                        contact_info.email = null;
                        contact_info.number = null;
                        contact_info.note = null;
                      "
                    />
                  </v-col>

                  <v-col v-if="contact_info.type === 'Email'">
                    <v-text-field
                      v-model="contact_info.email"
                      label="Email"
                      :rules="form.rules.emailRules"
                      outlined
                      @change="check_if_contact_info_exist(index)"
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col
                    v-if="
                      contact_info.type === 'Home Phone Number' ||
                      contact_info.type === 'Work Phone Number' ||
                      contact_info.type === 'Cell Phone Number'
                    "
                  >
                    <v-text-field
                      v-model="contact_info.number"
                      :label="contact_info.type"
                      :rules="form.rules.phoneRules"
                      outlined
                      maxlength="10"
                      @change="check_if_contact_info_exist(index)"
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col v-if="contact_info.type">
                    <v-textarea
                      v-model="contact_info.note"
                      label="Note"
                      outlined
                      rows="1"
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="1">
                    <v-btn
                      class="mt-2"
                      color="red"
                      icon
                      title="remove"
                      @click="userData.contact_infos.splice(index, 1)"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-btn @click="handle_add_contact_info"> + Add More </v-btn>

                <v-btn
                  v-if="userData.phone !== '' && component_type == 'edit'"
                  class="ml-2"
                  @click="handle_add_old_data('phone')"
                >
                  Copy Old Phone
                </v-btn>

                <v-btn
                  v-if="userData.email !== '' && component_type == 'edit'"
                  class="ml-2"
                  @click="handle_add_old_data('email')"
                >
                  Copy Old Email
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-5" />

            <v-switch
              v-model="userData.send_summary_offer_email"
              label="Send this user offer emails"
            ></v-switch>

            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="component_type == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateUser"
                  >Create User
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  >
                  </v-progress-circular>
                </v-btn>
                <v-btn
                  v-if="component_type == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateUser"
                  >Update User
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  >
                  </v-progress-circular>
                </v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'edit'"
                  @click="$router.go(-1)"
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'add'"
                  @click="$router.push({ path: `/dashboard/admin/user/list` })"
                  >Go To All Users List</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "user_add",
  props: ["dealId", "user_id"],
  data() {
    return {
      component_type: "",
      dealActiveOptions: ["active", "deactivated", "awarded", "completed"],
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",
      form: {
        valid: false,
        rules: {
          required: [(v) => !!v || "Name is required"],
          companyPhoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              "Phone must be numbers only (ex. 7803334444)",

            (v) =>
              (v && v.length == 10) ||
              "Phone number must be 10 digits no spaces or characters (format 7803334444)",
          ],
          phoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              "Phone must be numbers only (ex. 7803334444)",
            (v) => !!v || "Phone number is required",
            (v) =>
              (v && v.length == 10) ||
              "Phone number must be 10 digits no spaces or characters (format 7803334444)",
          ],
          emailRules: [
            (v) => (v && !!v) || "E-mail is required",
            (v) => (v && /.+@.+/.test(v)) || "E-mail must be valid",
          ],
        },
      },
      userData: {
        email: "",
        password: "",
        phone: "",
        first_name: "",
        last_name: "",
        contact_infos: [
          {
            order: 1,
            type: "Email",
            number: null,
            email: null,
            note: null,
          },

          {
            order: 2,
            type: "Cell Phone Number",
            number: null,
            email: null,
            note: null,
          },
        ],
        send_summary_offer_email: true,
      },

      // for contact_info
      confirming_contact_info: false,
      new_contact_info: {
        type: null,
        number: null,
        note: null,
        email: null,
      },

      temp_contact_info: null,
      existing_contact_infos: [],

      contact_type_options: [
        "Home Phone Number",
        "Cell Phone Number",
        "Work Phone Number",
        "Email",
      ],
    };
  },
  methods: {
    submitCreateUser() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.form.valid) {
        API()
          .post("/api/internal-admin/admin_create_user", this.userData)
          .then((res) => {
            console.log(res.data);
            this.formLoading = false;
            // console.log(res);
            this.$router.push(`/dashboard/admin/user/read/${res.data.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    submitUpdateUser() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.form.valid) {
        API()
          .patch(
            `/api/internal-admin/admin_update_user_information`,
            this.userData
          )
          .then((res) => {
            this.formLoading = false;

            this.$router.push(`/dashboard/admin/user/read/${this.userData.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    async get_user_info() {
      try {
        let user_info = await API().get(
          `/api/internal-admin/get_user_info_view/${this.user_id}`
        );

        const contact_infos = user_info.data.contact_infos;

        user_info.data.contact_infos = [];

        this.userData = user_info.data;

        for (const contact_info of contact_infos) {
          this.new_contact_info = contact_info;
          this.handle_add_contact_info();
        }

        // console.log("userData: ", this.userData);
      } catch (error) {
        console.log(error);
      }
    },

    handle_add_contact_info() {
      let new_order = 0;
      if (this.userData.contact_infos.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.userData.contact_infos.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_contact_info.order = new_order;

      this.userData.contact_infos.push(this.new_contact_info);
      this.new_contact_info = {
        type: null,
        number: null,
        note: null,
        email: null,
      };
    },

    handle_add_old_data(type) {
      let new_order = 0;

      if (this.userData.contact_infos.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.userData.contact_infos.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_contact_info.order = new_order;

      switch (type) {
        case "phone":
          if (this.userData.phone !== null) {
            this.new_contact_info.type = "Cell Phone Number";
            this.new_contact_info.number = this.userData.phone;
          } else {
            alert("No old phone number found");
          }
          break;
        case "email":
          if (this.userData.email !== null) {
            this.new_contact_info.type = "Email";
            this.new_contact_info.email = this.userData.email;
          } else {
            alert("No old email found");
          }
          break;
      }

      this.userData.contact_infos.push(this.new_contact_info);

      this.new_contact_info = {
        type: null,
        number: null,
        note: null,
        email: null,
      };
    },

    check_if_contact_info_exist(i) {
      const contact_info = this.userData.contact_infos[i];

      let contact_info_exist = null;

      switch (contact_info.type) {
        case "Home Phone Number":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.number === contact_info.number
          );
          break;

        case "Cell Phone Number":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.number === contact_info.number
          );
          break;

        case "Work Phone Number":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.number === contact_info.number
          );
          break;

        case "Email":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.email === contact_info.email
          );
          break;

        default:
          break;
      }

      if (contact_info_exist) {
        console.log("this contact exist", contact_info_exist);

        this.confirming_contact_info = true;
        this.temp_contact_info = contact_info_exist;
        this.temp_contact_info.index = i;
      } else {
        console.log("this contact does not exist on the database");
      }
    },

    handle_confirm_contact_info() {
      const i = this.temp_contact_info.index;
      delete this.temp_contact_info.index;

      switch (this.temp_contact_info.type) {
        case "Home Phone Number":
          this.userData.contact_infos[i].number = this.temp_contact_info.number;
          this.userData.contact_infos[i].id = this.temp_contact_info.id;
          break;

        case "Cell Phone Number":
          this.userData.contact_infos[i].number = this.temp_contact_info.number;
          this.userData.contact_infos[i].id = this.temp_contact_info.id;
          break;

        case "Work Phone Number":
          this.userData.contact_infos[i].number = this.temp_contact_info.number;
          this.userData.contact_infos[i].id = this.temp_contact_info.id;
          break;

        case "Email":
          this.userData.contact_infos[i].email = this.temp_contact_info.email;
          this.userData.contact_infos[i].id = this.temp_contact_info.id;
          break;

        default:
          break;
      }

      console.log("this.userData.contact_infos", this.userData.contact_infos);
      this.confirming_contact_info = false;
      this.temp_contact_info = null;
    },

    handle_close_confirm_contact_info() {
      const i = this.temp_contact_info.index;
      delete this.temp_contact_info.index;

      switch (this.temp_contact_info.type) {
        case "Home Phone Number":
          this.userData.contact_infos[i].number = null;
          break;

        case "Cell Phone Number":
          this.userData.contact_infos[i].number = null;
          break;

        case "Work Phone Number":
          this.userData.contact_infos[i].number = null;
          break;

        case "Email":
          this.userData.contact_infos[i].email = null;
          break;

        default:
          break;
      }

      this.confirming_contact_info = false;
      this.temp_contact_info = null;
    },

    async get_existing_contact_infos() {
      try {
        const response = await API().get(
          "api/internal-admin/customer/read_all_contact_infos"
        );

        // console.log("user contact infos", response);

        if (response.status === 200) {
          this.existing_contact_infos = response.data.filter(
            (el) => el.users.length > 0
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.get_existing_contact_infos();

    if (this.$route.name == "admin_add_user") {
      this.component_type = "add";
      this.componentLoading = false;
    }

    if (this.$route.name == "admin_edit_user") {
      this.component_type = "edit";
      this.get_user_info();
      this.componentLoading = false;
    }
  },
};
</script>

<style></style>
